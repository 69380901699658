<template>
  <div>
    <v-card class="transparent custom-font image-card mt-10 mt-sm-0" flat style="position:relative;">
      <v-icon
        class="black--text"
        large
        v-if="$route.name !== 'Login'"
        style="position: absolute; left: -40px;top:-20px;"
        @click="$router.go(-1)"
        >mdi-arrow-left-drop-circle-outline</v-icon
      >
      <v-card-text class="ps-md-16">
        <p
          class="custom-title custom-font text-h6 mb-0 text-center text-sm-left"
        >
          {{ $t("image.siginTitle1") }} <br />
          {{ $t("image.siginTitle2") }}
        </p>
      </v-card-text>
      <v-img :src="imageUrl" width="500" height="450px" contain class="" />
      <v-row class="ps-sm-4 ps-md-12 mx-0 justify-center justify-sm-start">
        <p class="text-caption mb-8 mb-md-0">{{ $t("image.footerTitle") }}</p>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: require("../../../assets/image2.jpeg"),
    };
  },
  mounted() {
    switch (this.$route.name) {
      case "Login":
        this.imageUrl = require("../../../assets/images/pngegg (10) 1 (1).png");
        break;
      case "UserPhone":
        this.imageUrl = require("../../../assets/images/pngegg (5) 1.png");
        break;
      case "Otp":
        this.imageUrl = require("../../../assets/images/imgbin_thai-fried-rice-moroccan-cuisine-vegetarian-cuisine-healthy-diet-meal-png 1 (1).png");
        break;
      case "ForgetPassword":
        this.imageUrl = require("../../../assets/images/Daco_4906241 1.png");
        break;
      case "PasswordEmailOtp":
      case "PasswordPhoneOtp":
        this.imageUrl = require("../../../assets/images/Daco_4373805 1.png");
        break;
      case "ResetPassword":
        this.imageUrl = require("../../../assets/images/NicePng_chicken-salad-png_6479147 1.png");
        break;
      default:
        this.imageUrl = require("../../../assets/images/pngegg (10) 1 (1).png");
    }
  },
};
</script>

